import { AScore } from '../../api/saq/types';
import { TableCell } from '@mui/material';
import React from 'react';
import { EditCell } from './EditCell';

type ScoreRowProps = {
  questionCode: string;
  answerScore: AScore;
  onShow: Function;
  onChange: Function;
  isEditable: boolean;
};

export const AnswerScoreCells = ({
  questionCode,
  answerScore,
  onShow,
  onChange,
  isEditable
}: ScoreRowProps) => {
  const onScoreChange = (value: string, label: string) => {
    const index = answerScore.scores.findIndex(
      (score) => score.label === label
    );

    if (index !== -1) {
      answerScore.scores[index] = {
        label,
        value: Number.parseFloat(value)
      };
      onChange(answerScore);
    }
  };

  return (
    <>
      <TableCell
        size="small"
        scope="row"
      >
        {answerScore.label}
      </TableCell>

      {answerScore.scores.map((score) => (
        <EditCell
          key={`${answerScore.id}-${score.label}`}
          questionCode={questionCode}
          label={score.label}
          value={score.value}
          isEditable={isEditable}
          onShow={onShow}
          onChange={onScoreChange}
        />
      ))}
    </>
  );
};
