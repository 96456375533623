import {
  ColumnMeta,
  CompanySizeScoreType,
  CoreSaqType,
  QMType,
  SubCategoryType
} from '../../../api/saq/types';
import {
  useCalculatedQuestions,
  useQuestionnaire,
  useQuestions,
  useScore,
  useQMQuestionnaire,
  useQMScore,
  useQMQuestions
} from '../../../api/saq/hooks/queries';
import {
  useUpdateQMQuestionScores,
  useUpdateQuestionScores
} from '../../../api/saq/hooks/mutations';

const columnsMeta: Record<string, ColumnMeta> = {
  subSection: { name: 'Sub-section', property: 'SUBSECTION_NAME' },
  question: { name: 'Question', property: 'QUESTION' },
  pillar: { name: 'Pillar', property: 'PILLAR' },
  isSaq: { name: 'SAQ vs Worker info table', property: 'IS_SAQ' }
};
enum ColumnName {
  possibleAnswers = 'Possible answers',
  micro = 'Micro',
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
  max = 'Max',
  default = 'Default'
}

export const getStrategy = ({
  questionnaireType
}: {
  questionnaireType: QMType | CoreSaqType;
}) => {
  switch (questionnaireType) {
    case CoreSaqType.GP:
    case CoreSaqType.RA:
    case CoreSaqType.SA:
    case CoreSaqType.SP: {
      const commonColumnsNames = [
        ColumnName.possibleAnswers,
        ColumnName.micro,
        ColumnName.small,
        ColumnName.medium,
        ColumnName.large,
        ColumnName.max
      ];
      const commonScoreTypes = [
        CompanySizeScoreType.Micro,
        CompanySizeScoreType.Small,
        CompanySizeScoreType.Medium,
        CompanySizeScoreType.Large
      ];
      return {
        service: {
          useQuestionnaire: useQuestionnaire,
          useScore: useScore,
          useQuestions: useQuestions,
          useCalculatedQuestions: useCalculatedQuestions,
          useUpdateQuestionScores: useUpdateQuestionScores
        },
        [SubCategoryType.ManagementControls]: {
          columnsMeta: [
            columnsMeta.pillar,
            columnsMeta.subSection,
            columnsMeta.question
          ],
          columnsNames: commonColumnsNames,
          scoreTypes: commonScoreTypes
        },
        [SubCategoryType.SiteCharacteristics]: {
          columnsMeta: [
            columnsMeta.pillar,
            columnsMeta.question,
            columnsMeta.isSaq
          ],
          columnsNames: commonColumnsNames,
          scoreTypes: commonScoreTypes
        }
      };
    }
    case QMType.ENVIRONMENT: {
      const commonColumnsNames = [
        ColumnName.possibleAnswers,
        ColumnName.default,
        ColumnName.max
      ];
      const commonColumnsMeta = [columnsMeta.subSection, columnsMeta.question];
      const commonScoreTypes = [CompanySizeScoreType.Default];
      return {
        service: {
          useQuestionnaire: useQMQuestionnaire,
          useScore: useQMScore,
          useQuestions: useQMQuestions,
          useCalculatedQuestions: () => ({ isLoading: false, data: undefined }),
          useUpdateQuestionScores: useUpdateQMQuestionScores
        },
        [SubCategoryType.ManagementControls]: {
          columnsMeta: commonColumnsMeta,
          columnsNames: commonColumnsNames,
          scoreTypes: commonScoreTypes
        },
        [SubCategoryType.SiteCharacteristics]: {
          columnsMeta: commonColumnsMeta,
          columnsNames: commonColumnsNames,
          scoreTypes: commonScoreTypes
        }
      };
    }
  }
};
