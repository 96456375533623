import React from 'react';
import { ColumnMeta, QScore, Section } from '../../api/saq/types';
import { TableBody } from '@mui/material';
import { QuestionRow } from './QuestionRow';

export const ScoresBody = ({
  columnsMeta,
  sections
}: {
  sections: Section<QScore>[];
  columnsMeta: ColumnMeta[];
}) => {

  return (
    <TableBody>
      {sections
        ?.flatMap((section) => section.questions)
        .map((questionScore: QScore) => (
          <QuestionRow
            key={`question-row-${questionScore.id}`}
            questionScore={questionScore}
            columnsMeta={columnsMeta}
          />
        ))}
    </TableBody>
  );
};
